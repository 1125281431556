import Tooltip from "@mui/material/Tooltip";
import ChipComponent from "../../../../components/Chip";
import StarRateIcon from "@mui/icons-material/StarRate";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import WarningIcon from "@mui/icons-material/Warning";
import CancelIcon from "@mui/icons-material/Cancel";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import { Fragment, useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Modal from "../../../../components/Modal";
import { useOutletContext } from "react-router-dom";
import { Button } from "@mui/material";
import { useAppContext } from "../../../../utils/ContextProvider.jsx";
import ShoeMatchingInformation from "../../../../components/Dashboard/Modal/ShoeMatchingInformation";
import RecommendationReasoningModal from "../../../../components/Dashboard/Modal/RecommendationReasoningModal";
import { shoeTraitMapping } from "../../../../utils/ValueMapping/index.js";

// Utility function to capitalize the first letter of a string
const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function ShoeCardResult({ shoe = null, requirements = null, open = null, setOpen = null, partnerDeals = [], expanded = null, handleAccordianClick = null, recommendation = null }) {

    var [partner] = useOutletContext()

    var { trackEvent } = useAppContext()

    var [shoePartnerDeals, setShoePartnerDeals] = useState([])

    var [selectedColorway, setSelectedColorway] = useState()

    var [reasoningModalOpen, setReasoningModalOpen] = useState(false)

    var [variable, setVariable] = useState(null)


    useEffect(() => {

        var availableDeals = partnerDeals && partnerDeals.length > 0 ? partnerDeals?.filter((deal) => deal.shoe._id.toString() === shoe.shoe._id.toString()) : []
        
        // Filter colorways based on partner deals
        const availableColorways = shoe.shoe.colorways?.filter((colorway) => 
            availableDeals.some(deal => deal.shoeColorway._id.toString() === colorway._id.toString())
        );

        // Set the first available colorway with a partner deal as the selected colorway
        setSelectedColorway(availableColorways[0] || shoe.shoe.colorways[0]);

        setShoePartnerDeals(availableDeals)

    }, [shoe, requirements, partnerDeals])

    var handleClick = (evt) => {
        trackEvent('View options', {shoe: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`, partner: partner.name})
    }

    var handleViewOptions = (evt) => {
        const firstDeal = shoePartnerDeals.find(deal => deal.shoeColorway._id.toString() === selectedColorway._id.toString());
        const url = firstDeal.affiliateLink || `${firstDeal.partner.baseUrl}${firstDeal.urlKey}${firstDeal.partner.referralUrlString}`;
        trackEvent('View options', {shoe: `${shoe.shoe.brand.name} ${shoe.shoe.model} ${shoe.shoe.version}`, partner: partner.name});
        window.open(url, '_blank');
    }

    const handleColorwayChange = (evt, colorway) => {
        setSelectedColorway(colorway)
    }

    console.log(variable)

    const handleTraitClick = (evt, trait) => {
        setVariable(trait)
        setReasoningModalOpen(true)
    }

    var content = 
    <Fragment>
        <ShoeMatchingInformation shoe={shoe} requirements={requirements} expanded={expanded} handleAccordianClick={handleAccordianClick} />
    </Fragment>
    

    return (
        <div className="col-10 col-md-4 mx-auto px-3">
            <div className="row">
                <div className="col grey-outline mx-2 my-3">
                    <div key={`${shoe.shoe._id}_match_pil`} className="row pt-3 mt-1 mb-3">
                        <div className="col-auto mx-auto">
                            <Tooltip 
                                arrow 
                                disableFocusListener 
                                enterTouchDelay={0} 
                                leaveTouchDelay={5000}
                                title={
                                <span>
                                    {shoe.criteria.sort((a, b) => b.priorityScore - a.priorityScore).map((criteria) => {
                                        var string = criteria.trait.split(/(?=[A-Z])/);
                                        var finalString = string.join(' ').toLowerCase()
                                        if (requirements[criteria.trait].reasoning.length > 0) {
                                            return (
                                                <div key={`${criteria.trait}TraitRow`} className="row">
                                                    <div className="col">
                                                        {criteria.pass == 'TRUE' ? `✅ ${shoeTraitMapping[criteria.trait] || finalString}` : `🚨 ${shoeTraitMapping[criteria.trait] || finalString}`}: {Array.isArray(shoe.shoe[criteria.trait]) ? shoe.shoe[criteria.trait].join(', ') : shoe.shoe[criteria.trait]}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })}
                                    <a onClick={(evt) => setReasoningModalOpen(true)} style={{textDecoration: 'underline', cursor: 'pointer'}}>Learn more</a>
                                </span>
                                }
                            >
                                <span>
                                    <ChipComponent
                                        icon={
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 99 ? <StarRateIcon /> : 
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 90 ? <ThumbUpOffAltIcon /> :
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 80 ? <SentimentNeutralIcon /> : 
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 65 ? <WarningIcon /> : <CancelIcon />
                                            
                                        }
                                        label={`${((shoe.score / shoe.maxScore) * 100).toFixed(0)} % match`}
                                        color={
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 95 ? 'success' : 
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 85 ? 'primary' : 
                                            ((shoe.score / shoe.maxScore) * 100).toFixed(0) > 75 ? 'warning' : 'error'
                                            
                                        }
                                        variant={(shoe.score / shoe.maxScore) < 1.0 ? 'outlined' : null}
                                    />
                                </span>
                            </Tooltip>
                        
                        </div>
                    </div>

                    {/* Brand image */}
                    <div key={`${shoe.shoe._id}_brandImage=`} className="row">
                        <div className="col-9 mx-auto text-center">
                            <img loading='eager' className='img img-fluid' src={`https://fittir-brand-images.s3.ap-southeast-2.amazonaws.com/original/${shoe.shoe.brand.slug}.png`} alt="" />
                        </div>
                    </div>


                    {/* Shoe image */}
                    <div className="row pt-2">
                        <div className="col-12 mx-auto text-center">
                            <img  loading='eager' className='img img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${selectedColorway?.colorwayCode}.png` } alt="" />
                        </div>
                    </div>

                    {/* Shoe name and price */}
                    <div className="row mt-3">
                        <div className="col-12 mx-auto text-center">
                            <h2>
                                {shoe.shoe.brand.name} {shoe.shoe.model} {shoe.shoe.version}
                            </h2>
                            <h3 className="mt-2">
                                {(() => {
                                    const deal = shoePartnerDeals.find(deal => deal.shoeColorway._id.toString() === selectedColorway._id.toString());
                                    if (deal) {
                                        return deal.specialPrice ? (
                                            <>
                                                <s style={{ fontSize: '0.9em' }}>${deal.price}</s> <span style={{ color: partner.primary, fontWeight: 'bold' }}>${deal.specialPrice}</span>
                                            </>
                                        ) : (
                                            `$${deal.price}`
                                        );
                                    }
                                    return '';
                                })()}
                            </h3>
                        </div>
                    </div>

                    <div className="row mt-2 px-2 justify-content-center">
                        {shoe.shoe.colorways
                        ?.filter(colorway => 
                            shoePartnerDeals.length === 0 || shoePartnerDeals.some(deal => deal.shoeColorway._id.toString() === colorway._id.toString())
                        )
                        .sort((a, b) => {
                            // Check if colorway has a deal
                            const hasDealA = shoePartnerDeals?.some(deal => deal.shoeColorway._id.toString() === a._id.toString() && (shoe.shoe.shoeShape === 'foot shaped' || requirements.width.traits.includes(deal.width))) ? 1 : 0;
                            const hasDealB = shoePartnerDeals?.some(deal => deal.shoeColorway._id.toString() === b._id.toString() && (shoe.shoe.shoeShape === 'foot shaped' || requirements.width.traits.includes(deal.width))) ? 1 : 0;

                            // Check if width is available for colorway A and B
                            const widthAvailableA = shoe.shoe.shoeShape === 'foot shaped' || a?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));
                            const widthAvailableB = shoe.shoe.shoeShape === 'foot shaped' || b?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption));

                            // Sort priority:
                            // 1. Has deal in the required width
                            // 2. Has width available but no deal
                            // 3. Has deal but not in the required width
                            // 4. No deal and no width available
                            if (hasDealA && widthAvailableA !== hasDealB && widthAvailableB) {
                                return hasDealB - hasDealA; // Prioritize deals with width availability
                            }
                            if (widthAvailableA !== widthAvailableB) {
                                return widthAvailableB - widthAvailableA; // Prioritize width availability
                            }
                            if (hasDealA !== hasDealB) {
                                return hasDealB - hasDealA; // Prioritize deals
                            }

                            return 0; // No change if both deal and width are the same
                        })
                        .map((colorway, index) => {
                            const widthAvailable = shoe.shoe.shoeShape !== 'foot shaped' ? colorway?.widthOptions?.some(widthOption => requirements.width.traits.includes(widthOption)) : true;
                            return (
                                <div className="col-2 mx-1 my-1" style={{cursor: widthAvailable ? 'pointer' : 'auto'}} key={`${shoe.shoe._id}Colorway${index}`} onClick={(evt) => handleColorwayChange(evt, colorway)}>
                                    <Tooltip title={widthAvailable ? `${colorway.colorwayName}` : `${colorway.colorwayName} (not available in ${requirements.width.traits.join(' or ')})`}>
                                        <div className="row">
                                            <div className="col py-1 py-xl-2" style={{border: selectedColorway?.colorwayCode === colorway.colorwayCode ? (widthAvailable ? '1px solid #0080FF' : '1px solid #687393') : '1px solid #D9D9D9', borderRadius: '0.5em', opacity: widthAvailable ? '1' : '0.3'}}>
                                                <img className='img-fluid' src={`https://fittir-shoe-images.s3.ap-southeast-2.amazonaws.com/${shoe.shoe.brand.slug}_${shoe.shoe.modelSlug}_${shoe.shoe.version}_${colorway.colorwayCode}.png`} alt="" />
                                            </div>
                                        </div>
                                    </Tooltip>
                                </div>
                            )
                        })}
                    </div>


                    {/* Shoe description */}
                    <div className="row mt-2 mb-3 justify-content-center">
                        {shoe.shoe.averageWebsiteScore && shoe.shoe.averageWebsiteScore !== '' && (
                            <div className="col-auto px-1 my-1">
                                <ChipComponent
                                    label={
                                        shoe.shoe.averageWebsiteScore > 9 ? <span style={{color: 'red'}}>❤️❤️❤️</span> :
                                        shoe.shoe.averageWebsiteScore > 8 ? <span style={{color: 'red'}}>❤️❤️</span> : <span style={{color: 'red'}}>❤️</span>
                                    }
                                    size='small'
                                    color='default'
                                />
                            </div>
                        )}
                        <div className="col-auto px-1 my-1">
                            <ChipComponent
                                label={`feels ${shoe.shoe.cushionType}`}
                                size='small'
                                variant='outlined'
                                color='success'
                            />
                        </div>
                        <div className="col-auto px-1 my-1">
                            <ChipComponent
                                label={`${shoe.shoe.cushion} cushion (${shoe.shoe.stackHeight}mm)`}
                                size='small'
                                color='default'
                            />
                        </div>
                        <div className="col-auto px-1 my-1">
                            <ChipComponent
                                label={`${shoe.shoe.stability} support`}
                                size='small'
                                color='default'
                            />
                        </div>
                        {shoe.shoe.surface === 'road' && shoe.shoe.carbonPlate == 'TRUE' && (
                            <div className="col-auto px-1 my-1">
                                <ChipComponent
                                    label="Carbon plate"
                                    size='small'
                                    color='default'
                                />
                            </div>
                        )}
                    </div>

                    <div className="row">
                        {/* Accordion for Details */}
                        <Accordion 
                            expanded={expanded === 'details'} 
                            onChange={handleAccordianClick('details')}
                            sx={{ boxShadow: 'none', border: 'none' }} // Remove border and shadow
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="details-content"
                                id="details-header"
                            >
                                <h4>Details</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                <p>
                                    {/* Add your details content here */}
                                    {shoe.shoe.descripton}
                                </p>
                            </AccordionDetails>
                        </Accordion>

                        {/* Accordion for Criteria */}
                        <Accordion 
                            expanded={expanded === 'criteria'} 
                            onChange={handleAccordianClick('criteria')}
                            sx={{ boxShadow: 'none', border: 'none' }} // Remove border and shadow
                        >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="criteria-content"
                                id="criteria-header"
                            >
                                <h4>Criteria</h4>
                            </AccordionSummary>
                            <AccordionDetails>
                                {shoe.criteria ? (
                                    <div className="row">
                                        <div className="col" style={{cursor: 'pointer'}}>
                                            {shoe.criteria.sort((a, b) => b.priorityScore - a.priorityScore).map((criterion, index) => {
                                                var string = criterion.trait.split(/(?=[A-Z])/);
                                                var finalString = string.join(' ').toLowerCase();
                                                return requirements[criterion.trait].reasoning.length > 0 && (
                                                    <div className="row justify-content-between" key={index} onClick={(evt) => handleTraitClick(evt, criterion.trait)}>
                                                        <div className="col-auto">
                                                            <p>
                                                                {capitalizeFirstLetter(shoeTraitMapping[criterion.trait] || finalString)}
                                                            </p>
                                                        </div>
                                                        <div className="col-auto">
                                                            <p>
                                                            {criterion.pass == 'TRUE' ? '✅' : '🚨'}
                                                            </p>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                        
                                    </div>
                                ) : (
                                    <p></p>
                                )}
                            </AccordionDetails>
                        </Accordion>
                    </div>

                    <div className="row mt-3 justify-content-center">
                        <div className="col-auto px-1 my-1">
                            <Button 
                                onClick={handleViewOptions}
                                target="_blank" 
                                variant="contained"
                                style={{backgroundColor: partner.secondary || null}}
                                >See options</Button>
                        </div>
                    </div>
                    <div className="row mt-2 mb-3 justify-content-center">
                        <div className="col-auto px-1 my-1">
                            <Button 
                                onClick={(evt) => setReasoningModalOpen(true)} 
                                variant="outlined"
                                style={{borderColor: partner.secondary || null, color: partner.secondary || null}}
                                >View reasoning</Button>
                        </div>
                    </div>
                    
                </div>
            </div>
            <RecommendationReasoningModal openState={reasoningModalOpen} setOpenState={setReasoningModalOpen} recommendation={recommendation} variable={variable} openVariable={variable} maxScore={shoe.maxScore}/>
        </div>
    )
}
