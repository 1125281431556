import { useState, useEffect } from "react"
import { Button } from "@mui/material"
import { useNavigate, useLocation } from "react-router-dom"
import { useAppContext } from "../../../../utils/ContextProvider.jsx/index"
import axios from "axios"
import DashboardHeader from "../../../../components/Dashboard/Header/index.jsx"
import TextField from "@mui/material/TextField"
import CircularProgress from "@mui/material/CircularProgress"
import Autocomplete from "@mui/material/Autocomplete"
import { runningProfileOptions } from "../../../../utils/ProfileOptions"

export default function BrandsPage () {

    var navigate = useNavigate()

    var { mapping, unprocessedMappings, allShoes } = useLocation().state || {}

    var [ allBrands, setAllBrands ] = useState([])
    var [partners, setPartners] = useState([])
    var [selectedPartner, setSelectedPartner] = useState(null)
    var [selectedBrands, setSelectedBrands] = useState([])

    useEffect(() => {
        setLoading(true)
        Promise.all([
            axios({
                url: `${requestUrl}/api/brands`,
                method: 'GET',
                withCredentials: true
            }),
            axios({
                url: `${requestUrl}/api/partners`,
                method: 'GET',
                withCredentials: true
            })
        ])
        .then(([brandsRes, partnersRes]) => {
            setAllBrands(brandsRes.data.allBrands)
            setPartners(partnersRes.data)
            setLoading(false)
        })
        .catch((err) => {
            console.error(err)
            setLoading(false)
            setAlertState({
                open: true,
                message: 'Error fetching data',
                severity: 'error'
            })
        })
    }, [])

    console.log(selectedPartner)
    console.log(selectedBrands)
    console.log(allBrands)

    

    const { isLoading, isAlert, setAlertState, setIsLoading, requestUrl, setIsAlert, setLoading, isProcessing, setProcessing } = useAppContext()

    const handleSubmit = (evt) => {
        // Make a put request to the partner endpoint to update the brands
        axios({
            url: `${requestUrl}/api/partners/${selectedPartner._id}`,
            method: 'PUT',
            withCredentials: true,
            data: { brands: selectedBrands }
        })
        .then((res) => {
            setIsAlert(pageData => ({
                ...pageData,
                success: 'Brands updated'
            }));
        })
    }


    return (
        <div>
            <DashboardHeader
                    heading={`Brands`}
                    subHeading={`Add brands to a partner`}
                    breadcrumb={[
                        {
                            title: 'Partners',
                            href: '/admin/partners'
                        }
                    
                    ]}
                />
                <div className="container-fluid dashboard-body pt-5 pb-5" id="recommendationsArea">
            <div className="container">
                <div className="row">
                    <div className="col">
                        <div className="row  mt-3">
                            <div className="col text-center">
                                <img className="img img-fluid" src={`/logos/partners/${selectedPartner?.slug}.svg`} alt="" />
                            </div>
                        </div>
                        
                        
                    </div>
                    <div className="col px-4 py-4" style={{backgroundColor: 'white', border: '1px solid white', borderRadius: '0.5em'}}>
                        <div className="row">
                            <div className="col">
                                <h2>Select a partner and brands</h2>
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 mx-auto mx-md-0">
                                <Autocomplete
                                    id="partner-select"
                                    options={partners}
                                    value={selectedPartner}
                                    autoHighlight
                                    getOptionLabel={(partner) => partner.name}
                                    onChange={(evt, value) => {
                                        setSelectedPartner(value);
                                        setSelectedBrands(value?.brands || []);
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select a partner"
                                            autoComplete="off"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off',
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mt-4">
                            <div className="col-12 mx-auto mx-md-0">
                                <Autocomplete
                                    id="brands-select"
                                    multiple
                                    options={allBrands}
                                    value={selectedBrands}
                                    autoHighlight
                                    getOptionLabel={(brand) => brand.name}
                                    onChange={(evt, value) => setSelectedBrands(value)}
                                    disabled={!selectedPartner}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Select brands"
                                            autoComplete="off"
                                            inputProps={{
                                                ...params.inputProps,
                                                autoComplete: 'off',
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="row mt-4 justify-content-end">
                            <div className="col-auto px-2">
                                <Button
                                variant="contained"
                                disabled={isProcessing ? true : false}
                                endIcon= {isProcessing ? <CircularProgress size={24} sx={{ color: 'white' }}/> : null}
                                onClick={handleSubmit}
                                >Update</Button>
                            </div>
                            
                        </div>
                    </div>
                </div>

            </div>
        </div>
        </div>

        
 
    )
}
