import { useLocation } from "react-router-dom"
import { Button } from "@mui/material"
import StartIcon from '@mui/icons-material/Start';
import "./index.css"
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Features from "./Features"
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { Helmet } from "react-helmet";
import { useInView } from 'react-intersection-observer';
import TestimonialSection from "../../../components/Website/TestimonialSection/index"
import BlogPreviewSection from "../../../components/Website/BlogPreviewSection/index"

export default function HomePage() {
    const [heroRef, heroInView] = useInView({ triggerOnce: true });
    const [problemRef, problemInView] = useInView({ triggerOnce: true });
    const [solutionRef, solutionInView] = useInView({ triggerOnce: true });
    const [aboutRef, aboutInView] = useInView({ triggerOnce: true });
    const [testimonialsRef, testimonialsInView] = useInView({ triggerOnce: true });
    const [ctaRef, ctaInView] = useInView({ triggerOnce: true });

    return (
        <div>
            <section ref={heroRef} className={`home-page-hero mt-5 pt-3 ${heroInView ? 'fade-in' : ''}`}>
                <div className="col-11 mx-auto">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-12 col-md-6 mx-auto">
                            <div className="row">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <h1 className="dashboard-text hero-heading">Find the right running shoe, <span className="heroTextHighlight">online.</span></h1>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <p className="dashboard-text">Running shoe recommendations for you based on your needs, goals and preferences. <span style={{textDecoration: 'underline'}}>Free-to-use,</span> and built by runners.</p>
                                </div>
                            </div>
                            <div className="row pt-3">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <Button 
                                        variant="contained"
                                        size="large"
                                        endIcon={<StartIcon/>}
                                        href="/shoe-finder/intro"
                                    >Get started</Button>
                                </div>
                            </div>
                            <div className="row justify-content-start mt-3">
                                <div className="col-10 mx-auto text-center text-md-start">
                                    <div className="row">
                                        <div className="col-auto mx-auto mx-md-0">
                                            <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
                                            <div className="elfsight-app-51516886-3f67-467e-8dde-6774a74d31fb" data-elfsight-app-lazy></div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6 text-center mx-auto ms-md-0 pt-5 pt-md-0">
                            <div className="row">
                                <div className="col-11 mx-auto">
                                    <img className="img img-fluid" src="/assets/hero_image_3.svg" alt="" />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </section>

            <section ref={problemRef} className={`problem-section mb-5 py-5 ${problemInView ? 'fade-in' : ''}`}>
                <div className="container-md">
                <div className="row mb-4">
                        <div className="col-10 col-md-7 mx-auto text-center">
                            <h2 style={{fontSize: '2rem'}}>
                                Finding a running shoe doesn't have to be overwhelming..
                            </h2>
                        </div>
                    </div>
                    <div className="row mb-3 align-items-center">
                        <div className="col-11 col-sm-auto mx-auto text-center problem-tile px-4 py-4">
                            <div className="row mb-2">
                                <div className="col">
                                    <h4>🤯 Over 200+ options</h4>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <h4>🔬 Hours spent researching</h4>
                                </div>
                            </div>
                            <div className="row mb-2 px-2">
                                <div className="col">
                                    <h4>💼 Restricted to certain brands offered by stores</h4>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <h4>🙅 Bad advice from "experts"</h4>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <h4>💰 Expensive mistakes</h4>
                                </div>
                            </div>
                            <div className="row mb-2">
                                <div className="col">
                                    <h4>🤕 Injuries from the wrong shoe</h4>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto mx-auto pt-3">
                            <a href="#solution-section" style={{textDecoration: 'none'}}><p style={{color: 'white'}}> <ArrowDownwardIcon /> There's an easier way..</p></a>
                        </div>
                    </div>
                </div>
                    
            </section>

            <section ref={solutionRef} className={`solution-section my-5 py-2 py-md-5 ${solutionInView ? 'fade-in' : ''}`} id="solution-section">
                <div className="container-lg">
                    <div className="row">
                        <div className="col-11 mx-auto">
                            <div className="row align-items-center">
                                
                                <Features />
                                
                            </div>
                            <div className="row mt-4">
                                <div className="col-auto mx-auto mx-md-0">
                                    <a href="/how-it-works" style={{textDecoration: 'none'}}><p style={{color: 'black', fontWeight: 'bold', textDecoration: 'underline'}}>Learn more about how it works <ArrowRightAltIcon /> </p></a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                
             </section>    

             <section ref={aboutRef} className={`about-section ${aboutInView ? 'fade-in' : ''}`}>
                <div className="container pt-4">
                    <div className="row my-3">
                        <div className="col-11 col-md-10 col-lg-8 mx-auto my-4 px-3 about-tile">
                            <div className="row mt-3 pt-5">
                            <div className="col-9 mx-auto text-center">
                                <h2 style={{fontSize: '2rem'}}>Built by runners, with the help of runners, for runners</h2>
                            </div>
                            </div>
                            <div className="row pt-3 text-center">
                                <div className="col-11 col-lg-8 mx-auto">
                                    <p className="dashboard-text">After 4+ years of struggling trying to find the right running shoe bouncing between bad advice, trendy brands and biased recommendations. I (Anthony 👋) eventually landed on the right shoe after trying 7 different options, wasting $100's and getting a number of injuries in the process .</p>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-11 col-lg-8 mx-auto">
                                    <p className="dashboard-text">All it took was one expert, and a 5 minute assessment with no brand bias for me to realise that there is a formula to shoes that isn't as complex as we come to think.</p>
                                </div>
                            </div>
                            <div className="row text-center">
                                <div className="col-11 col-lg-8 mx-auto">
                                    <p className="dashboard-text">Since that moment, I've teamed up with specialists and experts to help runners who were in a similar position to what I once was to make sure <strong>no-one gets denied the right to run because of a bad shoe choice.</strong> </p>
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col-9 mx-auto">
                                    <div className="row justify-content-center align-items-center">
                                        <div className="col-4 col-lg-2">
                                            <img className="img img-fluid" src="/team/anthony.png" alt="" />
                                        </div>
                                        <div className="col-auto">
                                            <div className="row mt-2">
                                                <div className="col">
                                                    <h4 className="my-0">Anthony, Founder ✌️</h4>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col">
                                                    <p className="my-0">Ultra-marathon runner</p>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto mx-auto pt-1 pb-3">
                            <a href="/about" style={{textDecoration: 'none'}}><p style={{color: 'white'}}>Learn more about us <ArrowRightAltIcon /> </p></a>
                        </div>
                    </div>
                    
                    
                </div>
            </section>   

            <TestimonialSection />

            {/* <section ref={testimonialsRef} className={`testimonials-section my-5 py-5 ${testimonialsInView ? 'fade-in' : ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-9 mx-auto text-center">
                            <h2>Hear from some of the 300+ runners who have used Fittir to find the right shoe...</h2>
                        </div>
                    </div>
                    <div className="row py-3">
                        <div className="col">
                        <script src="https://static.elfsight.com/platform/platform.js" data-use-service-core defer></script>
                        <div className="elfsight-app-bcb30900-50b5-4432-85ab-3237c3670510" data-elfsight-app-lazy></div>
                        </div>
                    </div>
                    
                </div>
            </section>  */}

            <section ref={ctaRef} className={`CTA-section my-5 py-5 ${ctaInView ? 'fade-in' : ''} gradient-background`}>
                <div className="container">
                    <div className="row">
                        <div className="col-11 col-md-9 mx-auto text-center">
                            <h2 style={{fontSize: '2rem'}}>It's free to use, and takes 5 minutes to get your shoe recommendation</h2>
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="col-auto mx-auto">
                            <Button
                                href="/shoe-finder/intro"
                                color="secondary"
                                style={{backgroundColor: 'white', color: 'black'}}
                                size="large"
                                endIcon={<StartIcon/>}>
                                Get started
                            </Button>
                        </div>
                    </div>
                    
                </div>
            </section>  

            {/* <BlogPreviewSection /> */}

        </div>
        
    )
}
